<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item>
            <el-input v-model="selectForm.Factory" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="selectForm.UserDpt" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="selectForm.UserPost" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="selectForm.RoleName"
              style="width: 100%"
              @change="RoleChange($event)"
            >
              <el-option label="基础信息" value="基础信息"></el-option>
              <el-option label="离职信息" value="离职信息"></el-option>
              <el-option label="试用信息" value="试用信息"></el-option>
              <el-option label="奖惩管理" value="奖惩管理"></el-option>
              <el-option label="培训信息" value="培训信息"></el-option>
              <el-option
                label="手机解绑/分公司考勤"
                value="手机解绑/分公司考勤"
              ></el-option>
              <el-option label="考勤轨迹" value="考勤轨迹"></el-option>
              <el-option label="薪资信息" value="薪资信息"></el-option>
               <el-option label="绩效权限" value="绩效权限"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="selectForm.TypeName"
              @change="WorkTypeModel($event)"
              placeholder="职务工作类型"
              style="width: 100%"
            >
              <el-option
                v-for="item in WorkTypeLists"
                :key="item.id"
                :label="item.workTypeName"
                :value="item.workTypeName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="selectForm.isAll"
              placeholder="是否获取顶级部门"
              style="width: 100%"
            >
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="selectForm.UserName" :disabled="true">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="chooseUser"
              ></el-button>
            </el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div style="width: 100%">
        
        <el-tree :data="alldepartmentdata"
                 show-checkbox
                 :expand-on-click-node="false"
                 :default-expand-all="true"
                 :check-strictly="isAll"
                 :check-on-click-node="true"
                 highlight-current
                 :props="defaultProps"
                 :indent="30"
                 node-key="id"
                 ref="tree">
        </el-tree>
    </div>

    <el-dialog
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import { Loading } from "element-ui";
import {
  GetDepartmentTree,
  GetWorkTypes,
  GetUserPostDptOrg,
  UserInfoCompetenceAddInfo,
  SalaryCompetenceAddInfo,
  DimissionCompetenceAddInfo,
  UserInfoCompetenceGetInfo,
  SalaryCompetenceGetInfo,
  DimissionCompetenceGetInfo,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UserChoose from "../../components/UserChoose";
export default {
  components: { Toolbar, UserChoose },
  data() {
    return {
      buttonListmsg: "",
      buttonList: [],
      isAll: false,
      selectForm: {
        Factory: "",
        UserDpt: "",
        UserPost: "",
        RoleName: "基础信息",
        UserName: "",
        UserId: "",
        Type: "",
        isAll: "否",
        competenceArry: [],
      },
      TreeProps: {
        children: "children",
        label: "lable",
        },
        defaultProps: {
            children: 'children',
            label: 'label'
        },
      choosedusers: [],
      alldepartmentdata: [],

      WorkTypeLists: [],
      addUserVisible: false,
    };
  },
  methods: {
    chooseUser() {
      this.addUserVisible = true;
    },
    handleRole() {
      if (this.selectForm.UserId == "") {
        this.$message({
          message: "请先选择分配的人员",
          type: "error",
        });
        return;
      }
      var isAll = this.selectForm.isAll;
      if (isAll == "是") {
        var role = this.$refs.tree
          .getCheckedNodes()
          .concat(this.$refs.tree.getHalfCheckedNodes());
      } else {
        var role = this.$refs.tree.getCheckedNodes();
      }
      console.log(role);
      if (role.length == 0) {
        this.$message({
          message: "请先勾选权限",
          type: "error",
        });
        return;
      }
      var addArray = [];
      for (var i = 0; i < role.length; i++) {
        addArray.push({
          competenceDptId: role[i].id,
          competenceDpt: role[i].lable,
        });
      }

      this.selectForm.competenceArry = addArray;
      switch (this.selectForm.RoleName) {
        case "基础信息":
          this.UserInfoCompetenceAddInfo();
          break;
        case "离职信息":
          this.DimissionCompetenceAddInfo();
          break;
        case "试用信息":
          break;
        case "奖惩管理":
          break;
        case "培训信息":
          break;
        case "手机解绑/分公司考勤":
          break;
        case "考勤轨迹":
          break;
        case "薪资信息":
          this.SalaryCompetenceAddInfo();
          break;
           case "绩效权限":
          this.MeritCompetenceAddInfo();
          break;
      }
    },
    UserInfoCompetenceAddInfo() {
      const loading = this.$loading({
        lock: true,
        text: "分配中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      UserInfoCompetenceAddInfo(this.selectForm).then((res) => {
        loading.close();
        if (res.data.success) {
          this.$message({
            message: "分配成功",
            type: "success",
            duration: 5000,
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    MeritCompetenceAddInfo() {
      if (this.selectForm.Type == "") {
        this.$message({
          message: "请选择工作类型",
          type: "error",
          duration: 5000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "分配中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$api.MeritCompetenceAddInfoList(this.selectForm).then((res) => {
        loading.close();
        if (res.data.success) {
          this.$message({
            message: "分配成功",
            type: "success",
            duration: 5000,
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    SalaryCompetenceAddInfo() {
      if (this.selectForm.Type == "") {
        this.$message({
          message: "请选择工作类型",
          type: "error",
          duration: 5000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "分配中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      SalaryCompetenceAddInfo(this.selectForm).then((res) => {
        loading.close();
        if (res.data.success) {
          this.$message({
            message: "分配成功",
            type: "success",
            duration: 5000,
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    DimissionCompetenceAddInfo() {
      const loading = this.$loading({
        lock: true,
        text: "分配中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      DimissionCompetenceAddInfo(this.selectForm).then((res) => {
        loading.close();
        if (res.data.success) {
          this.$message({
            message: "分配成功",
            type: "success",
            duration: 5000,
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }

      this.selectForm.UserId = newdata[0].Id;
      this.selectForm.UserName = newdata[0].UserNameClaim;
        GetUserPostDptOrg({ userId: newdata[0].Id }).then((res) => {
            console.log("就是这里", res);
            if (res.data.success ) {
          //this.selectForm.Factory = res.data.response[0].OrganizationName;
                this.selectForm.UserDpt = res.data.response.DepartmentName;
                this.selectForm.UserPost = res.data.response.PostName;
          this.getUserRole();
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },
    RoleChange() {
      this.getUserRole();
    },
    getUserRole() {
      if (this.selectForm.UserId == "") {
        this.$message({
          message: "请先选择分配的人员",
          type: "error",
        });
        return;
      }
      switch (this.selectForm.RoleName) {
        case "基础信息":
          this.UserInfoCompetenceGetInfo();
          break;
        case "离职信息":
          this.DimissionCompetenceGetInfo();
          break;
        case "试用信息":
          break;
        case "奖惩管理":
          break;
        case "培训信息":
          break;
        case "手机解绑/分公司考勤":
          break;
        case "考勤轨迹":
          break;
        case "薪资信息":
          this.SalaryCompetenceGetInfo();
          break;
           case "绩效权限":
          this.MeritCompetenceGetInfo();
          break;
      }
    },
    UserInfoCompetenceGetInfo() {
      const loading = this.$loading({
        lock: true,
        text: "权限树加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      UserInfoCompetenceGetInfo({ userId: this.selectForm.UserId }).then(
        (res) => {
          var RoleArray = [];
          var roleArrays = res.data.response;
          for (var i = 0; i < roleArrays.length; i++) {
            RoleArray.push(roleArrays[i].CompetenceDptId);
          }
          this.$refs.tree.setCheckedKeys(RoleArray);
          loading.close();
        }
      );
    },
    DimissionCompetenceGetInfo() {
      const loading = this.$loading({
        lock: true,
        text: "权限树加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      DimissionCompetenceGetInfo({ userId: this.selectForm.UserId }).then(
        (res) => {
          var RoleArray = [];
          var roleArrays = res.data.response;
          for (var i = 0; i < roleArrays.length; i++) {
            RoleArray.push(roleArrays[i].CompetenceDptId);
          }
          this.$refs.tree.setCheckedKeys(RoleArray);
          loading.close();
        }
      );
    },
    MeritCompetenceGetInfo() {
      if (this.selectForm.Type == "") {
        this.$message({
          message: "请选择工作类型",
          type: "error",
          duration: 5000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "权限树加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$api.MeritCompetenceGetInfoList({
        userId: this.selectForm.UserId,
        type: this.selectForm.Type,
      }).then((res) => {
        var RoleArray = [];
        var roleArrays = res.data.response;
        for (var i = 0; i < roleArrays.length; i++) {
          RoleArray.push(roleArrays[i].CompetenceDptId);
        }
        this.$refs.tree.setCheckedKeys(RoleArray);
        loading.close();
      });
    },
    SalaryCompetenceGetInfo() {
      if (this.selectForm.Type == "") {
        this.$message({
          message: "请选择工作类型",
          type: "error",
          duration: 5000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "权限树加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      SalaryCompetenceGetInfo({
        userId: this.selectForm.UserId,
        type: this.selectForm.Type,
      }).then((res) => {
        var RoleArray = [];
        var roleArrays = res.data.response;
        for (var i = 0; i < roleArrays.length; i++) {
          RoleArray.push(roleArrays[i].CompetenceDptId);
        }
        this.$refs.tree.setCheckedKeys(RoleArray);
        loading.close();
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    getData() {
        GetDepartmentTree().then((res) => {
            if (res.data.code == 200) {
                this.alldepartmentdata = res.data.data.dept;
            }
      });
    },
      WorkTypeModel(id) {
      var obj = {};
      obj = this.WorkTypeLists.find((item) => {
        //model就是上面的数据源
          return item.workTypeName === id; //筛选出匹配数据
      });
      this.selectForm.Type = obj.Id;
    },
  },
  mounted() {
      this.getData();
      var data = {
          status: '1',
          pageNum: '1',
          pageSize: '10',
      };
      GetWorkTypes(data).then((res) => {
        this.WorkTypeLists = res.data.data.list;
    });

    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

